export const environment = {
  production: true,
  apiPrefix: 'https://',
  apiSuffix: '.api.creevx.com/api',
  envVar: {
      NAME: "CreevX",
      LOGO: "https://scappsab.sirv.com/Images/dark-logo.svg",
      LOGO_WHITE: "https://scappsab.sirv.com/Images/full-logo.svg",
      PRIMARY_COLOR: "#004980",
      FAVICON: "https://scappsab.sirv.com/Images/favicon.png"
},
};
